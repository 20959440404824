/**
 * Created by olga on 12.03.2024
 */

var MarkView = cc.Node.extend({
    ctor: function (mark) {
        this.mark = mark;
        this._super();

        var markView = this.getMarkImage(mark);

        this.addChild(markView);
        this.setLocalZOrder(10);
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound(cleverapps.styles.MarkView);

        mark.on("collect", this.createListener(this.collect.bind(this)), this);
    },

    collect: function (targetName, callback) {
        cleverapps.audio.playSound(BlocksGame.MARKS[this.mark.type].explodeEffect || bundles.blocks.urls.explode_mark_effect);

        var target = cleverapps.aims.getTarget(targetName, { noDefault: true });
        if (!target) {
            this.removeFromParent();
            return;
        }

        var flyIcon = this.getFlyingImage(this.mark);
        this.addChild(flyIcon, 10);
        flyIcon.setPositionRound(this.width / 2, this.height / 2);

        flyIcon.runAction(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.ScaleTo(0.3, 2).easing(cc.easeOut(2)),
                    new cc.ScaleTo(0.3, 1).easing(cc.easeInOut(2))
                ),
                new cc.Sequence(
                    new cc.DelayTime(0.4),
                    AnimationsLibrary.animateCollect(flyIcon, target.icon || target, {
                        sound: BlocksGame.MARKS[this.mark.type].collectEffect
                    }),
                    new cc.RemoveSelf(),
                    new cc.CallFunc(function () {
                        callback && callback();
                    })
                )
            )
        );
    },

    getMarkImage: function (mark) {
        var markView;
        if (mark.getImage()) {
            markView = new cc.Sprite(mark.getImage());
        } else {
            markView = new cleverapps.Spine(mark.getSpine());
            markView.setAnimation(0, "idle", true);
        }
        return markView;
    },

    getFlyingImage: function (mark) {
        var flyingView;
        var goal = Game.currentGame.goals.findTargetFor(mark.getTargetName());
        if (goal) {
            flyingView = GoalView.getGoalImage(goal.type);
        } else {
            flyingView = new cleverapps.Spine(BlocksGame.MARKS[mark.type].flyingView);
        }
        flyingView.setAnimation(0, "animation", false);
        return flyingView;
    }
});

cleverapps.styles.MarkView = {
    x: { align: "center" },
    y: { align: "center" }
};