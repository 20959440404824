/**
 * Created by mac on 2/28/23
 */

var BlocksGame = function (level, options) {
    GameBase.call(this, level, options);

    this._modeCounter = this.savedGame._modeCounter || 0;
    this.mode = this.savedGame.mode || level.content.mode || 0;

    this.maxMode = BlocksGame.MODES.length - 1;

    if (this.savedGame.maxMode !== undefined) {
        this.maxMode = this.savedGame.maxMode;
    } else if (level.content.maxMode !== undefined) {
        this.maxMode = level.content.maxMode;
    }

    var GridClassName = cleverapps.environment.isEditorScene() ? EditorGrid : Grid;
    this.grid = new GridClassName(level.content, this.savedGame.cells);
    this.pieces = new Pieces(this, this.savedGame.pieces);

    this.hover = {};

    this.explodedLines = 0;

    if (this.levelWithTutorial()) {
        this.tutorial = new BlocksTutorial(this.level, this.savedGame.tutorial);
    }

    this.score.on("onUpdate", this.onScoreUpdate.bind(this));
    this.score.on("onShow", cleverapps.highscore.showView.bind(cleverapps.highscore));
    this.score.on("onHide", cleverapps.highscore.hideView.bind(cleverapps.highscore));

    this.movesSinceClear = 0;

    if (level.content.goals && level.content.goals.length) {
        this.goals = new Goals(this.levelContent, this.savedGame.goals);
    }

    this.history = new UndoHistory(this.savedGame.history);

    if (cleverapps.config.debugMode) {
        this.orangery = new Orangery(BlocksOrangery);
    }

    this.counter.registerStage(1, this.checkWin.bind(this));
    this.counter.registerStage(2, this.nextStep.bind(this));

    this.goalCounter = new GameCounter();
    this.goalCounter.registerStage(0, function () {
        this.counter.trigger();
    }.bind(this));
};

var Game = BlocksGame;

BlocksGame.prototype = Object.create(GameBase.prototype);
BlocksGame.constructor = BlocksGame;

BlocksGame.prototype.checkWin = function () {
    if (this.getMode() === GameBase.MODE_HIGHSCORE) {
        return;
    }
    if (this.goals && this.goals.isDone && this.outcome === GameBase.OUTCOME_UNKNOWN) {
        this.win();
    }
};

BlocksGame.prototype.animateOutcome = function () {
    if (this.goalCounter.isActive()) {
        return;
    }

    GameBase.prototype.animateOutcome.call(this);
};

BlocksGame.prototype.lose = function () {
    if (Game.currentGame.isMemorableGame()) {
        this.setOutcome(GameBase.OUTCOME_VICTORY);
    } else {
        this.setOutcome(GameBase.OUTCOME_LOST);
    }
};

BlocksGame.prototype.getMode = function () {
    return this.level.episodeType === Episode.Types.HIGHSCORE_MODE ? GameBase.MODE_HIGHSCORE : GameBase.MODE_REGULAR;
};

BlocksGame.prototype.resetHover = function () {
    if (this.hover.index === undefined) {
        return;
    }
    var piece = this.pieces.getPiece(this.hover.index);
    var blocks = Forms.convertFormToCoordinates(piece.getForm());
    blocks.forEach(function (block) {
        var cell = this.grid.getCell(this.hover.x + block.x, this.hover.y + block.y);
        cell.resetHover();
    }, this);

    if (this.hover.rows) {
        this.grid.resetExplodeHint(this.hover.rows, this.hover.columns);
    }

    this.hover = {};
};

BlocksGame.prototype.dragMovePiece = function (piece, pos) {
    var coords = this.getPieceCoordinatesInGrid(piece, pos);
    var x = coords.x;
    var y = coords.y;
    var index = piece.index;

    if (!this.grid.isInside(x, y)) {
        this.resetHover();
        return;
    }

    if (this.hover.index === index && this.hover.x === x && this.hover.y === y) {
        return;
    }

    this.resetHover();

    if (this.placementErrors(piece.getForm(), x, y) !== 0) {
        return;
    }

    this.hover.index = index;
    this.hover.x = x;
    this.hover.y = y;

    this.takeAndPlacePiece(this.hover.index, x, y, true);
};

BlocksGame.prototype.dragEndPiece = function (piece, pos) {
    var coords = this.getPieceCoordinatesInGrid(piece, pos);
    var x = coords.x;
    var y = coords.y;
    var index = piece.index;

    this.grid.hideHint();
    this.resetHover();

    if (this.grid.isInside(x, y) && this.placementErrors(piece.getForm(), x, y) === 0) {
        this.currentMove = piece.getInfo();
        this.currentMove.position = coords;
        this.currentMove.isLast = this.pieces.pieces.filter(function (form) {
            return !form;
        }).length === (this.pieces.pieces.length - 1);

        this.takeAndPlacePiece(index, x, y, false);
    } else {
        cleverapps.audio.playSound(bundles.blocks.urls.wrong_effect);
        this.tutorial && this.tutorial.showHint();
    }
};

BlocksGame.prototype.getPieceCoordinatesInGrid = function (piece, pos) {
    var gridPosition = this.grid.getView().convertToNodeSpace(pos);
    var cellPosition = GridView.positionToCell(gridPosition);
    var x = cellPosition.x;
    var y = cellPosition.y;

    x = Math.round(x - piece.getCenter().x);
    y = Math.round(y - piece.getCenter().y);
    return cc.p(x, y);
};

BlocksGame.prototype.undo = function (move) {
    if (move.isLast) {
        for (var index = 0; index < this.pieces.pieces.length; index++) {
            if (index !== move.index) {
                this.pieces.take(index);
            }
        }
    }
    this.pieces.clearHint(move.index);
    this.pieces.replacePiece(move);

    var piece = this.pieces.getPiece(move.index);
    this.removePiece(piece, move.position.x, move.position.y);
    cleverapps.audio.playSound(bundles.game.urls.explosion_piece_effect);

    this.checkMoves();

    this.score.setPoints(move.score);
    if (move.highscore) {
        cleverapps.highscore.setScore(move.highscore);
    }

    this.moves--;
    this.movesSinceClear--;
};

BlocksGame.prototype.removePiece = function (piece, x, y) {
    var blocks = Forms.convertFormToCoordinates(piece.getForm());
    blocks.forEach(function (block) {
        var cell = this.grid.getCell(x + block.x, y + block.y);
        cell.remove(piece.getColor());
    }.bind(this));
};

BlocksGame.prototype.isMemorableGame = function () {
    return !cleverapps.gameModes.alwaysNoMemorable && this.getMode() === GameBase.MODE_HIGHSCORE && this.score.points >= 200;
};

BlocksGame.prototype.setMoves = function () {
    cleverapps.audio.playSound(bundles.blocks.urls.prolongation_effect);

    this.pieces.onHidePieces();
    this.pieces.pieces = [undefined, undefined, undefined];

    this.applySelectionAlgorithm({
        findSolvable: true,
        findMaxExplode: false,
        proposedIndexes: false,
        enableDuplicate: true,
        callback: function (forms) {
            forms = (forms.items || []).map(function (solvable) {
                return Forms.ALL_POSSIBLE_VARIANTS[solvable];
            });

            this.pieces.nextPieces = forms.map(function (form) {
                return {
                    form: form,
                    color: this.pieces.calcPieceColor()
                };
            }.bind(this));

            this.counter.trigger(150);
        }.bind(this)
    });
};

BlocksGame.prototype.noMoves = function () {
    if (!cleverapps.user.checkAvailable(cleverapps.Availables.FREE_PROLONGATION)) {
        this.trigger("outOfMoves");
        return;
    }

    var offer = this.prolongation.hasOffer() && this.prolongation.getOffer(Prolongation.TYPES.MOVES);
    if (offer && offer.priceType === "free") {
        this.prolongationUsed = true;
        this.trigger("outOfMoves", offer);
        return;
    }

    var booster = cleverapps.boosters.listBoosters().find(function (booster) {
        return !this.prolongationUsed && booster.isForceAvailable();
    }.bind(this));

    this.prolongationUsed = true;

    if (booster) {
        booster.showForce();
    } else {
        this.trigger("outOfMoves", offer);
    }
};

BlocksGame.prototype.placePiece = function (piece, x, y, options) {
    options = options || {};
    var color = piece.getColor();
    var form = piece.getForm();

    var blocks = Forms.convertFormToCoordinates(form);
    blocks.forEach(function (block) {
        var mark = piece.getMark(block.x, block.y);
        var components = piece.getComponents(block.x, block.y);

        var cell = this.grid.getCell(x + block.x, y + block.y);
        if (!cell) {
            return;
        }
        if (options.isHover) {
            cell.setHover({ color: color });
        } else {
            cell.resetHover();
            this.grid.setCellProperties(x + block.x, y + block.y, { color: color, mark: mark && mark.type, components: components });
        }
    }, this);

    var linesAmount = this.checkLines(color, options.isHover);
    if (!options.isHover && !piece.fromOrangery) {
        this.playPiece(blocks.length);
        if (linesAmount > 0) {
            this.history.reset();
        }
        this.counter.trigger();
    }
};

BlocksGame.prototype.playPiece = function (size) {
    if (cleverapps.config.editorMode) {
        return;
    }

    this.moves++;
    if (this.moves === 10) {
        cleverapps.playSession.set(cleverapps.EVENTS.MOVES10_DAU, true);
    }

    this._modeCounter++;

    if (!this.currentMove) {
        return;
    }

    this.currentMove.score = this.score.points;
    if (this.getMode() === GameBase.MODE_HIGHSCORE) {
        this.currentMove.highscore = cleverapps.highscore.getScore();
    }

    this.history.add(this.getCommand(this.currentMove));

    this.movesSinceClear++;
    this.score.addPoints(size * BlocksGame.PIECE_COST);

    if (this._modeCounter >= 3 * BlocksGame.MODES[this.mode].steps) {
        this._modeCounter = 0;

        if (this.mode !== this.maxMode) {
            this.mode++;
            this.grid.changeModeFrameColor(BlocksGame.MODES[this.mode].color);
        }
    }

    cleverapps.audio.playSound(bundles.blocks.urls.tile_set_effect);
};

BlocksGame.prototype.takeAndPlacePiece = function (index, x, y, isHover) {
    var piece = this.pieces.getPiece(index);

    if (!isHover) {
        this.pieces.take(index, true);
    }

    this.placePiece(piece, x, y, { isHover: isHover });
};

BlocksGame.prototype.showGoalsMessage = function (callback) {
    cleverapps.timeouts.setTimeout(function () {
        this.trigger("showGoalsMessage");
    }.bind(this), 200);

    cleverapps.timeouts.setTimeout(callback, 1700);
};

BlocksGame.prototype.showStartGameMessage = function (f, silent) {
    if (this.goals && !silent) {
        this.showGoalsMessage(f);
    } else {
        f();
    }
};

BlocksGame.prototype.checkLines = function (color, isHover) {
    var lines = this.grid.listFilledLines();
    var rows = lines.rows;
    var columns = lines.columns;
    var linesAmount = rows.length + columns.length;

    if (linesAmount > 0) {
        if (isHover) {
            this.grid.setExplodeHint(rows, columns, color);
            this.hover.rows = rows;
            this.hover.columns = columns;
        } else {
            this.grid.explodeRowsOrColumns(rows, columns, color);
            this.explodedLines += linesAmount;

            var multiplier = 1;

            if (linesAmount >= 4) {
                multiplier = 3;
            } else if (linesAmount >= 3) {
                multiplier = 2;
            }

            var scoreAmount = linesAmount * multiplier * BlocksGame.LINE_COST;
            this.score.addPoints(scoreAmount, true);

            if (linesAmount > 1) {
                this.grid.showCombo(scoreAmount);
            }

            if (this.movesSinceClear >= 10 && this.grid.isEmpty()) {
                this.grid.fill(Grid.FILL_EFFECTS.FromCenter);

                this.movesSinceClear = 0;
            }
        }
    }

    return linesAmount;
};

BlocksGame.prototype.onScoreUpdate = function (amount) {
    if (!this.highScoreBroken && this.getMode() === GameBase.MODE_HIGHSCORE && amount > cleverapps.highscore.getScore()) {
        this.highScoreBroken = true;

        cleverapps.exclamation.show("message.newHighscore");
        cleverapps.audio.playSound(bundles.game.urls.message_effect);
    }

    if (this.getMode() === GameBase.MODE_HIGHSCORE && amount > cleverapps.highscore.getScore()) {
        cleverapps.highscore.setScore(amount);
    }

    var goal = Game.currentGame.goals.findTargetFor(BlocksGame.GOALS_TYPES.SCORE);
    if (goal) {
        goal.setAmount(amount);
    }
};

BlocksGame.prototype.hint = function (form) {
    var bestValue = Infinity;
    var bestX, bestY;
    var isSingleOption = false;

    for (var i = 0; i < Grid.WIDTH; i++) {
        for (var j = 0; j < Grid.HEIGHT; j++) {
            var errors = this.placementErrors(form, i, j);
            if (errors < bestValue) {
                bestValue = errors;
                bestX = i;
                bestY = j;
                isSingleOption = true;
            } else if (errors === bestValue) {
                isSingleOption = false;
            }
        }
    }

    return {
        single: isSingleOption,
        x: bestX,
        y: bestY,
        errors: bestValue
    };
};

BlocksGame.prototype.placementErrors = function (form, x, y) {
    var errors = this.grid.placeFormErrors(form, x, y, true);

    if (this.tutorial && this.tutorial.isActive() && !this.tutorial.isCorrectPlace(form, x, y)) {
        errors++;
    }

    return errors;
};

BlocksGame.prototype.checkMoves = function () {
    if (this.pieces.isEmpty()) {
        return;
    }

    var noMoves = true;
    for (var index = 0; index < this.pieces.pieces.length; index++) {
        var piece = this.pieces.pieces[index];
        if (piece) {
            var hint = this.hint(piece.getForm());
            if (hint.errors === 0) {
                piece.enable();
                noMoves = false;
            } else {
                piece.disable();
            }
            if (hint.single) {
                piece.setHint(hint);
            } else {
                piece.clearHint();
            }
        }
    }

    if (noMoves) {
        this.noMoves();
    }
};

BlocksGame.prototype.nextStep = function () {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }

    if (!this.pieces.isEmpty()) {
        this.checkMoves();
        return;
    }

    if (this.tutorial && this.tutorial.isActive()) {
        this.tutorial.wantNextStep();
    }

    this.pieces.getNewPieces(this.checkMoves.bind(this));

    this.tutorial && this.tutorial.showHint();
};

BlocksGame.prototype.showScreen = function (f, silent) {
    cleverapps.meta.compound(f, [
        function (f) {
            if (silent) {
                this.grid.iterateCells(function (cell) {
                    cell.updateViewProperties();
                });
                f();
            } else {
                this.grid.fill(Grid.FILL_EFFECTS[this.getMode() === GameBase.MODE_REGULAR ? "LinearFromBottom" : "FromCenter"], f);
            }
        }.bind(this),

        function (f) {
            if (this.pieces.nextPieces) {
                this.pieces.getNewPieces(f, silent);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            this.trigger("showGoals", silent);
            this.score.show();

            this.grid.changeModeFrameColor(BlocksGame.MODES[this.mode].color);

            f();
        }.bind(this)
    ]);
};

BlocksGame.prototype.decCounter = function (f) {
    if (this.levelWithTutorial()) {
        f();
        return;
    }
    GameBase.prototype.decCounter.call(this, f);
};

BlocksGame.prototype.displayTutorial = function (f) {
    if (!this.tutorial.hasStep()) {
        GameBase.prototype.decCounter.call(this, f);
        return;
    }

    cleverapps.meta.setEventNodes([cleverapps.scenes.getRunningScene()]);

    this.tutorial.startTutorial();
    this.tutorial.onFinishCallback = f;

    new ActionPlayer([
        function (f) {
            this.pieces.getNewPieces(f);
        }.bind(this),

        function (f) {
            cleverapps.meta.display({
                stack: true,
                focus: "TutorialForce",
                action: function (f) {
                    this.tutorial.showForce(f);
                }.bind(this)
            });

            this.tutorial.showHint();
            GameBase.prototype.decCounter.call(this, f);
        }.bind(this)

    ]).play();
};

BlocksGame.prototype.getInfo = function () {
    var info = GameBase.prototype.getInfo.call(this);

    if (!this.stopped) {
        info.score = this.score.getPoints();
        info.moves = this.moves;
        info._modeCounter = this._modeCounter;
        info.mode = this.mode;
        info.maxMode = this.maxMode;

        info.cells = this.grid.getInfo();
        info.pieces = this.pieces.getInfo();
        info.tutorial = this.tutorial && this.tutorial.getInfo();
        info.history = this.history.getInfo();
        if (this.goals) {
            info.goals = this.goals.getInfo();
        }
    }
    return info;
};

BlocksGame.prototype.guessPosition = function (piece) {
    return {
        x: (Grid.WIDTH - piece[0].length) >> 1,
        y: (Grid.HEIGHT - piece.length + 1) >> 1
    };
};

BlocksGame.prototype.customGridForPiece = function (form) {
    var pos = this.guessPosition(form);
    var x = pos.x;
    var y = pos.y;

    var colors = [];
    for (var c = 0; c <= BlocksGame.COLORS.length; c++) {
        colors.push(c);
    }

    cleverapps.Random.shuffle(colors);

    var needColumns = form[0].length > 2 || form.length === 1;
    var needRows = form.length > 1 || !needColumns;

    var i, j;

    var rowColor = colors[0];
    if (needRows) {
        for (i = 0; i < form.length; i++) {
            for (j = 0; j < Grid.WIDTH; j++) {
                this.grid.setCellProperties(j, y + i, { color: rowColor, fadeIn: true });
            }
        }
    }

    var columnColor = colors[1];
    if (needColumns) {
        for (j = 0; j < form[0].length; j++) {
            for (i = 0; i < Grid.HEIGHT; i++) {
                this.grid.setCellProperties(x + j, i, { color: columnColor, fadeIn: true });
            }
        }
    }

    for (i = 0; i < form.length; i++) {
        for (j = 0; j < form[i].length; j++) {
            if (form[i][j] === "x") {
                this.grid.setCellProperties(x + j, y + i, { color: BlocksGame.COLOR_NONE });
            }
        }
    }
};

BlocksGame.prototype.getPercentOfCompletion = function () {
    if (!cleverapps.highscore) {
        return 1;
    }
    return this.score.getPoints() / cleverapps.highscore.getScore();
};

BlocksGame.prototype.calcPieceForms = function (callback) {
    var mode = BlocksGame.MODES[this.mode];
    var times = mode.times || 1;
    var proposedIndexes = undefined;
    var bestScore = Infinity;

    for (var i = 0; i < times; i++) {
        var move = this.proposeMove(mode.filter);

        if (move.score < bestScore) {
            bestScore = move.score;
            proposedIndexes = move.chosen;
        }
    }

    var debugCircleColor = cleverapps.styles.COLORS.COLOR_GRAY;

    var findMaxExplode = false;
    if (mode.maxExplode) {
        findMaxExplode = Math.random() < parseInt(mode.maxExplode) / 100;
    }

    var findSolvable = false;
    if (mode.solvable) {
        findSolvable = Math.random() < parseInt(mode.solvable) / 100;
    }

    this.applySelectionAlgorithm({
        findSolvable: findSolvable,
        findMaxExplode: findMaxExplode,
        proposedIndexes: proposedIndexes,
        callback: function (result) {
            if (findMaxExplode) {
                debugCircleColor = cleverapps.styles.COLORS.COLOR_RED;
            } else if (findSolvable) {
                debugCircleColor = cleverapps.styles.COLORS.LIFE_GREEN;
            }
            this.grid.updatePiecesData(debugCircleColor, result);

            callback(cleverapps.Random.shuffle(result.items).map(function (index) {
                return Forms.ALL_POSSIBLE_VARIANTS[index];
            }));
        }.bind(this)
    });
};

BlocksGame.prototype.applySelectionAlgorithm = function (options) {
    cleverapps.webWorker.postMessage({
        grid: this.grid.toStringArray(),
        findMaxExplode: options.findMaxExplode,
        findSolvable: options.findSolvable,
        proposed: options.proposedIndexes,
        enableDuplicate: options.enableDuplicate
    });

    cleverapps.webWorker.onmessage = function (event) {
        options.callback(event.data);
    };
};

BlocksGame.prototype.proposeMove = function (filter) {
    var allForms = Forms.ALL_POSSIBLE_VARIANTS;

    var indexes = allForms.map(function (item, index) {
        return index;
    });

    if (filter) {
        indexes = indexes.filter(function (index) {
            return filter(allForms[index]);
        });
    }

    indexes = cleverapps.Random.shuffle(indexes);

    var chosen = indexes.slice(0, 2);
    for (var i = 2; i < indexes.length; i++) {
        if (this.hint(allForms[indexes[i]]).errors === 0) {
            chosen.push(indexes[i]);
            break;
        }
    }

    if (chosen.length === 2) {
        chosen.push(indexes[2]);
    }

    var score = Forms.GetHardness(allForms[chosen[0]]) + Forms.GetHardness(allForms[chosen[1]]) + Forms.GetHardness(allForms[chosen[2]]);

    return {
        chosen: chosen,
        score: score
    };
};

BlocksGame.prototype.shakeField = function (options) {
    this.trigger("shakeField", options);
};

BlocksGame.prototype.getCommand = function (move) {
    return {
        move: move,
        undo: this.undo.bind(this, move),
        redo: function () {}
    };
};

BlocksGame.prototype.useLives = function () {
    return GameBase.prototype.useLives.call(this) && this.getMode() !== GameBase.MODE_HIGHSCORE;
};

BlocksGame.MODES = [
    {
        steps: 3,
        filter: function (form) {
            return Forms.GetHardness(form) <= Forms.__BY_HARDNESS[0].points;
        },
        solvable: "100%",
        color: cleverapps.styles.COLORS.MODE_COLOR_GREEN
    },
    {
        steps: 20,
        filter: function (form) {
            return Forms.GetHardness(form) <= Forms.__BY_HARDNESS[1].points;
        },
        solvable: "100%",
        maxExplode: "25%",
        color: cleverapps.styles.COLORS.MODE_COLOR_BLUE
    },
    {
        steps: 30,
        times: 4,
        solvable: "100%",
        maxExplode: "25%",
        color: cleverapps.styles.COLORS.MODE_COLOR_YELLOW
    },
    {
        steps: 40,
        times: 3,
        solvable: "80%",
        maxExplode: "25%",
        color: cleverapps.styles.COLORS.MODE_COLOR_ORANGE
    },
    {
        times: 2,
        filter: function (form) {
            return Forms.GetHardness(form) >= Forms.__BY_HARDNESS[1].points;
        },
        maxExplode: "20%",
        color: cleverapps.styles.COLORS.MODE_COLOR_RED
    }
];

BlocksGame.PIECE_COST = 1;
BlocksGame.LINE_COST = 10;
BlocksGame.COLOR_NONE = -1;

BlocksGame.COLORS = [{
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_RED,
    image: bundles.blocks.frames.cell_bg_red_png,
    skin: "red"
}, {
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_GREEN,
    image: bundles.blocks.frames.cell_bg_green_png,
    skin: "green"
}, {
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_BLUE,
    image: bundles.blocks.frames.cell_bg_blue_png,
    skin: "blue"
}, {
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_YELLOW,
    image: bundles.blocks.frames.cell_bg_yellow_png,
    skin: "yellow"
}, {
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_PURPLE,
    image: bundles.blocks.frames.cell_bg_purple_png,
    skin: "purple"
}, {
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_CYAN,
    image: bundles.blocks.frames.cell_bg_cyan_png,
    skin: "pink"
}, {
    rgb: cleverapps.styles.COLORS.BLOCK_COLOR_ORANGE,
    image: bundles.blocks.frames.cell_bg_orange_png,
    skin: "orange"
}];

BlocksGame.GOALS_TYPES = {
    CRYSTAL_BLUE: "crystal_blue",
    CRYSTAL_PINK: "crystal_pink",
    CRYSTAL_ORANGE: "crystal_orange",
    SCORE: "score"
};

BlocksGame.MARKS = {
    goal_blue: {
        image: bundles.blocks.frames.cell_crystal_blue_png,
        flyingView: bundles.blocks.jsons.letter_mark_json,
        disableImage: bundles.blocks.frames.cell_crystal_blue_disable_png,
        target: BlocksGame.GOALS_TYPES.CRYSTAL_BLUE,
        collectEffect: bundles.blocks.urls.blue_mark_collect_effect
    },
    goal_pink: {
        image: bundles.blocks.frames.cell_crystal_pink_png,
        disableImage: bundles.blocks.frames.cell_crystal_pink_disable_png,
        target: BlocksGame.GOALS_TYPES.CRYSTAL_PINK,
        collectEffect: bundles.blocks.urls.pink_mark_collect_effect
    },
    goal_orange: {
        image: bundles.blocks.frames.cell_crystal_orange_png,
        disableImage: bundles.blocks.frames.cell_crystal_orange_disable_png,
        target: BlocksGame.GOALS_TYPES.CRYSTAL_ORANGE,
        collectEffect: bundles.blocks.urls.orange_mark_collect_effect
    },
    letter: {
        spine: bundles.blocks.jsons.letter_mark_json,
        flyingView: bundles.blocks.jsons.letter_mark_json,        
        getTarget: function () {
            return "mission_reward" + Mission.TYPE_LETTER;
        },
        explodeEffect: bundles.blocks.urls.explode_letter_effect,
        collectEffect: bundles.blocks.urls.letter_mark_collect_effect
    }
};

BlocksGame.DISABLE_COLOR = {
    image: bundles.blocks.frames.cell_bg_gray_png
};

BlocksGame.FIELD_MAX_SCALE = 1.4;
