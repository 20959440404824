/**
 * Created by olga on 13.03.2024
 */

var ScoreModeButton = cleverapps.UI.Button.extend({
    ctor: function () {
        var styles = cleverapps.styles.ScoreModeButton;
        this._super({
            width: styles.width,
            height: styles.height,
            text: "* " + Messages.getLocalized("ScoreModeButton.text"),
            icons: {
                "*": bundles.buttons_main.frames.zen_png
            },
            type: cleverapps.styles.UI.Button.Images.button_blue,

            onClicked: function () {
                cleverapps.meta.display({
                    focus: "ScoreLevel",
                    action: function (f) {
                        var level = new Level(Episode.Types.HIGHSCORE_MODE, 0);
                        cleverapps.meta.wantsToPlay(f, level);
                    }
                });
            }
        });

        this.setPositionRound(cleverapps.styles.ScoreModeButton.position);
    }
});

cleverapps.styles.ScoreModeButton = cleverapps.overrideStyles(cleverapps.styles.PlayButtonView, {
    position: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 135 }
        },
        {
            x: { align: "center", dx: 175 },
            y: { align: "bottom", dy: 140 }
        },
        {
            x: { align: "center", dx: 175 },
            y: { align: "bottom", dy: 160 }
        }
    ]
}, true);