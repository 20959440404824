/**
 * Created by olga on 01.03.2024
 */

var GoalView = cc.Node.extend({
    ctor: function (goal) {
        this._super();

        this.goal = goal;
        this.type = goal.type;

        this.collected = goal.getAmount();

        this.setAnchorPoint(0.5, 0.5);

        this.icon = GoalView.getGoalImage(this.type);

        this.amountType = this.getAmountType();

        this.content = new TextWithIcon("%%", {
            font: cleverapps.styles.FONTS.GOAL_TEXT,
            margin: cleverapps.styles.GoalView.margin,
            icons: {
                "%%": this.icon,
                "!!": bundles.checkbox.frames.check_mark_png
            },
            iconScale: 1
        });

        this.update();

        this.addChild(this.content);
        cleverapps.UI.wrap(this);

        this.updateSize();
        this.setVisible(false);
        this.setCascadeOpacityEnabledRecursively(true);
        this.setOpacity(0);

        goal.on("onCollect", this.createListener(this.animateCollect.bind(this)));
        goal.on("update", this.createListener(this.update.bind(this)));
        goal.on("show", this.createListener(this.show.bind(this)));
    },

    animateSparks: function () {
        var sparks = new cleverapps.Spine(bundles.blocks.jsons.sparks_json);
        this.icon.addChild(sparks);
        sparks.setSkin("blue");
        sparks.setPositionRound(this.icon.width / 2, this.icon.height / 2);
        sparks.setAnimation(0, "animation", false);
        sparks.setCompleteListenerRemove();
    },

    show: function (silent) {
        this.setVisible(true);
        this.icon.setAnimation(0, "idle", false);
        if (silent) {
            this.setOpacity(255);
        } else {
            this.setScale(0.6);
            this.animateSparks();
            this.runAction(new cc.FadeIn(0.3));
            this.runAction(new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()));
        }
    },

    hide: function (silent) {
        if (!silent) {
            this.icon.setAnimation(0, "fadeout", false);
            this.icon.setCompleteListener(function () {
                this.setVisible(false);
            }.bind(this));
            this.runAction(new cc.FadeOut(0.3));
        } else {
            this.setVisible(false);
        }
    },

    updateSize: function () {
        this.content.setOptions({
            direction: cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL
        });
        this.content.reshape();
    },

    updateAmount: function () {
        var amount;
        if (this.amountType === GoalView.DISPLAYED_AMOUNT.TOTAL) {
            amount = this.collected;
        } else if (this.amountType === GoalView.DISPLAYED_AMOUNT.REMAINING) {
            amount = this.goal.getTarget() - this.collected;
        } else if (this.amountType === GoalView.DISPLAYED_AMOUNT.FRACTION) {
            amount = this.collected + "/" + this.goal.getTarget();
        }

        this.content.setString("%%" + amount);
    },

    animateCollect: function (delta) {
        this.update(delta);
        this.icon.stopAllActions();
        this.icon.setRotation(0);
        this.icon.setScale(1);

        this.icon.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.ScaleTo(0.15, 1.3),
                new cc.ScaleTo(0.15, 1)
            ),
            new cc.Sequence(
                new cc.RotateBy(0.3, 15),
                new cc.RotateBy(1.2, -15).easing(cc.easeElasticOut(0.4))
            )
        ));
    },

    update: function (delta) {
        delta = delta || 0;
        this.collected += delta;

        this.updateAmount();

        if (this.collected >= this.goal.getTarget() && this.goal.withTarget()) {
            this.setCompleted();
        }
    },

    setCompleted: function () {
        this.content.setString("%%!!");
        cleverapps.audio.playSound(bundles.blocks.urls.goal_is_completed);
    },

    getAmountType: function () {
        if (this.goal.withTarget()) {
            return GoalView.DISPLAYED_AMOUNT.REMAINING;
        }

        return GoalView.DISPLAYED_AMOUNT.TOTAL;
    }

});

GoalView.getGoalImage = function (goalType) {
    return new cleverapps.Spine(bundles.blocks.jsons[goalType + "_goal_json"]);
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GOAL_TEXT: {
        name: "default",
        size: 45
    }
});

cleverapps.styles.GoalView = {
    margin: 10,

    offsetY: 30
};

GoalView.DISPLAYED_AMOUNT = {};
GoalView.DISPLAYED_AMOUNT.TOTAL = 0;
GoalView.DISPLAYED_AMOUNT.REMAINING = 1;
GoalView.DISPLAYED_AMOUNT.FRACTION = 2;
