/**
 * Created by olga on 14.05.2024
 */

var GoalsMessageContent = cleverapps.Layout.extend({
    ctor: function (goals) {
        var styles = cleverapps.styles.GoalsMessageContent;

        var message = Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE ? "GameMessage.highScoreMode.text" : "GameMessage.text";
        var text = this.text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.GAME_MESSAGE_TEXT);
        this.text.setCascadeOpacityEnabledRecursively(true);
        this.text.setOpacity(0);

        this.goals = goals;

        this.views = [];
        Object.values(goals).forEach(function (goal) {
            var icon = GoalView.getGoalImage(goal.type);

            var amount = cleverapps.UI.generateImageText(goal.getRemaining() || "", cleverapps.styles.FONTS.GOAL_TEXT);

            var view = new cleverapps.Layout([icon, amount], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.goalMargin
            });
            view.icon = icon;
            view.amount = amount;
            view.target = cleverapps.aims.getTarget(goal.type);
            view.type = goal.type;

            this.views.push(view);
        }.bind(this));

        var goalsLayout = new cleverapps.Layout(this.views, { direction: cleverapps.UI.HORIZONTAL, margin: styles.goalsMargin });

        this._super([text, goalsLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    onShow: function () {
        var styles = cleverapps.styles.GoalsMessageContent;
        this.text.setOpacity(0);
        this.text.runAction(new cc.Sequence(
            new cc.FadeIn(0.3),
            new cc.DelayTime(0.5),
            new cc.FadeOut(0.3)
        ));

        this.views.forEach(function (view) {
            view.replaceParentSamePlace(cleverapps.scenes.getMovingNode());

            var pos = view.parent.convertToNodeSpace(view.target.parent.convertToWorldSpace(view.target.getPosition()));
            var points = [view.getPosition(), cc.p(view.x + styles.midPoint.dx, view.y + styles.midPoint.dy), pos];

            view.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    view.icon.setAnimationAndIdleAfter("fadein", "idle");
                }),
                new cc.TargetedAction(view.amount, new cc.FadeIn(0.3)),
                new cc.DelayTime(0.2),
                new cc.TargetedAction(view.amount, new cc.FadeOut(0.2)),
                new cc.Spawn(
                    new cc.BezierTo(0.75, points).easing(cc.easeIn(1)),
                    new cc.Sequence(
                        new cc.DelayTime(0.4),
                        new cc.PlaySound(bundles.blocks.urls.fly_to_goal)
                    ),
                    new cc.Sequence(
                        new cc.RotateBy(0.5, -90),
                        new cc.RotateBy(0.25, 90)
                    ),
                    new cc.ScaleTo(0.75, 0.6).easing(cc.easeIn(3))
                ),
                new cc.CallFunc(function () {
                    this.goals[view.type].showView();
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        }.bind(this));
    }

});

cleverapps.styles.GoalsMessageContent = {
    margin: 50,
    padding: {
        bottom: 30
    },

    goalMargin: 10,
    goalsMargin: 20,

    midPoint: {
        dx: 150,
        dy: 150
    }

};