/**
 * Created by Andrey Popov on 22.03.2023
 */

cleverapps.overrideStyles(cleverapps.styles.FingerView, {
    offset: {
        x: 20,
        y: -10
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorY: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ScoreStatsComponent, {
    background: false
});

cleverapps.overrideStyles(cleverapps.styles.PlayButtonView, {
    position: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 252 }
        },
        {
            x: { align: "center", dx: -175 },
            y: { align: "bottom", dy: 140 }
        },
        {
            x: { align: "center", dx: -175 },
            y: { align: "bottom", dy: 160 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
    positions: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 364 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 255 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 278 }
        }
    ],
    icon: {
        bg: {
            padding: {
                x: 10
            },
            y: { align: "center", dy: -4 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 235,
    height: 82,

    icon: {
        x: { align: "left", dx: -20 }
    },

    position: [
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -140 }
        },
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -140 }
        },
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -140 }
        }
    ],

    text: {
        x: { align: "center", dx: 30 },
        y: { align: "center", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MinimalDialogue, {
    person: {
        y: { align: "center", dy: -21 }
    },

    text: {
        arrow: {
            left: {
                y: { align: "center", dy: -15 }
            },
            right: {
                y: { align: "center", dy: -15 }
            }
        }
    }
});