/**
 * Created by mac on 2/28/23
 */

var BlocksGameScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        this._super();

        this.createHighScoreView();

        var gridView = this.gridView = new GridView(this.game.grid);
        this.game.grid.iterateCells(function (cell) {
            cell.updateViewProperties({ color: BlocksGame.COLOR_NONE });
        });
        var piecesView = this.piecesView = new PiecesView(this.game.pieces);

        if (this.game.goals) {
            this.goalsView = new GoalsView(this.game.goals);
        }

        var gridLayout = this.gridLayout = new cleverapps.Layout([this.goalsView, gridView], {
            direction: cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.BlocksGameScene.margin
        });

        this.field = new cleverapps.Layout([gridLayout, piecesView], {
            direction: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.BlocksGameScene.margin
        });

        this.addChild(this.field);

        if (cleverapps.gameModes.hidePieces) {
            piecesView.setVisible(false);
        }

        FieldScoreView.fillPool();

        var orangery = Game.currentGame.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.meta.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        var replaceAllBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_REPLACE_ALL);
        if (replaceAllBooster && replaceAllBooster.isAvailable()) {
            var replaceAllButtonBooster = this.replaceAllButtonBooster = new ReplaceAllButtonBooster(replaceAllBooster);
            this.addChild(replaceAllButtonBooster);

            this.replaceAllControl = new HidingNode(replaceAllButtonBooster);
            cleverapps.meta.registerControl(replaceAllButtonBooster.getControlId(), this.replaceAllControl);
        }

        var clearPiecesBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_CLEAR_PIECES);
        if (clearPiecesBooster && clearPiecesBooster.isAvailable()) {
            var clearPiecesButtonBooster = this.clearPiecesButtonBooster = new ClearPiecesButtonBooster(clearPiecesBooster);
            this.addChild(clearPiecesButtonBooster);

            this.clearPiecesControl = new HidingNode(clearPiecesButtonBooster);
            cleverapps.meta.registerControl(clearPiecesButtonBooster.getControlId(), this.clearPiecesControl);
        }

        var undoPieceBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_UNDO_PIECE);
        if (undoPieceBooster && undoPieceBooster.isAvailable()) {
            var undoPieceButtonBooster = this.undoPieceButtonBooster = new UndoPieceButtonBooster(undoPieceBooster);
            this.addChild(undoPieceButtonBooster);

            this.undoPieceControl = new HidingNode(undoPieceButtonBooster);
            cleverapps.meta.registerControl(undoPieceButtonBooster.getControlId(), this.undoPieceControl);
        }

        cleverapps.playSession.set(cleverapps.EVENTS.START_LEVEL_DAU, true);

        this.updateSize();
        this.setupChildren();

        this.game.on("showGoals", function (silent) {
            this.goalsView && this.goalsView.show(silent);
        }.bind(this), this);
        this.game.on("showGoalsMessage", this.showGoalsMessage.bind(this), this);
        this.game.on("shakeField", this.shakeField.bind(this), this);
    },

    outOfMoves: function (offer) {
        cleverapps.meta.display({
            focus: "noMovesWindow",
            actions: [
                function (f) {
                    cleverapps.audio.playSound(bundles.blocks.urls.nomoves_exclamation_effect);
                    cleverapps.exclamation.show("message.noMoves", Exclamation.Types.Warning);
                    cleverapps.timeouts.setTimeout(f, 1500);
                },

                function (f) {
                    if (!offer) {
                        Game.currentGame.lose();
                        f();
                        return;
                    }

                    new ProlongationWindow({
                        offer: offer,
                        onGiveUp: function () {
                            Game.currentGame.lose();
                        }
                    });
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            ]
        });
    },

    showGoalsMessage: function () {
        var messageContent = new GoalsMessageContent(this.game.goals.elements);
        var messageView = new GameMessageView(undefined, {
            content: messageContent
        });

        this.addChild(messageView);
        messageContent.onShow();
    },

    createHighScoreView: function () {
        var highscore = cleverapps.highscore.getScore();
        if (!cleverapps.gameModes.hideHighScore && Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE && highscore > 0) {
            var highScoreView = this.highScoreView = new HighScoreView(cleverapps.highscore);
            this.addChild(highScoreView);
        }
    },

    getOverlappingNodes: function () {
        var items = [this.upMenuContainer, this.replaceAllControl, this.clearPiecesControl, this.clearPiecesControl, this.horizontalBlocker];

        if (this.highScoreView) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                var highScoreViewContainer = new cc.Node();
                highScoreViewContainer.setPositionRound(this.highScoreView.getBoundingBox());
                highScoreViewContainer.setContentSize(this.width, this.highScoreView.height);
                this.addChild(highScoreViewContainer);
                items.push(highScoreViewContainer);
            } else {
                items.push(this.highScoreView);
            }
        }

        return items.filter(Boolean);
    },

    scaleGameField: function () {
        var centerPoint = cc.p(this.width / 2, this.height / 2);
        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            if (this.undoPieceControl) {
                centerPoint.x = this.undoPieceControl.getGlobalBoundingBox().x / 2;
            }
        }
        cleverapps.UI.inflateToBoundaries(this.field, this.getOverlappingNodes(), {
            lovesPosition: this.convertToWorldSpace(centerPoint),
            padding: cleverapps.styles.BlocksGameScene.padding[cleverapps.resolution.mode],
            maxScale: BlocksGame.FIELD_MAX_SCALE
        });
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this._super();
        var styles = cleverapps.styles.BlocksGameScene;

        this.field.setPositionRound(styles.field);
        if (this.highScoreView) {
            this.highScoreView.setPositionRound(styles.highScore.position);
        }
    },

    updateSize: function () {
        this._super();

        if (!this.isAllLoaded) {
            return;
        }

        var styles = cleverapps.styles.BlocksGameScene;

        var anchorPoint = styles.piecesView.anchor[cleverapps.resolution.mode];
        this.piecesView.setAnchorPoint(anchorPoint.x, anchorPoint.y);
        this.piecesView.resize();

        this.goalsView && this.goalsView.updateSize();

        var margin = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.verticalMargin : styles.margin;
        var gridLayoutDirection = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL;
        var fieldLayoutDirection = cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL;

        this.gridLayout.setOptions({
            direction: gridLayoutDirection,
            margin: margin
        });

        this.field.setOptions({
            direction: fieldLayoutDirection,
            margin: margin
        });

        this.gridLayout.reshape();
        this.field.reshape();

        if (this.highScoreView) {
            this.highScoreView.setScale(styles.highScore.scale[cleverapps.resolution.mode]);
        }
    },

    shakeField: function (options) {
        if (this.field.shakeAction && !this.field.shakeAction.isDone()) {
            return;
        }
        options = options || {};
        var action = options.fullShake ? BlocksGameScene.SHAKE_ACTIONS.FULL.apply(this) : BlocksGameScene.SHAKE_ACTIONS.DOWN_UP.apply(this);
        this.field.shakeAction = this.field.runAction(action);
    }
});

GameScene = BlocksGameScene;

BlocksGameScene.SHAKE_ACTIONS = {
    FULL: function () {
        return AnimationsLibrary.shake(this.field, {
            power: 10,
            points: [
                { x: 1, y: 1, r: 0 },
                { x: 1, y: -1, r: 0 },
                { x: -1, y: -1, r: 0 },
                { x: -1, y: 1, r: 0 },
                { x: 0, y: 0, r: 0 }
            ]
        });
    },
    DOWN_UP: function () {
        return AnimationsLibrary.shake(this.field, {
            power: 15,
            points: [
                { x: 0, y: -1, r: 0 },
                { x: 0, y: 2, r: 0 },
                { x: 0, y: 0, r: 0 }
            ]
        });
    }
};

cleverapps.styles.BlocksGameScene = {
    padding: [{
        top: 30,
        bottom: 60,
        left: 30,
        right: 30
    }, {
        top: 30,
        bottom: 140,
        left: 30,
        right: 30
    }, {
        top: 30,
        bottom: 180,
        left: 30,
        right: 30
    }],

    margin: 70,
    verticalMargin: 30,

    highScore: {
        scale: [0.7, 0.8, 1],
        position: [{
            x: { align: "left", dx: 20 },
            y: { align: "top", dy: -120 }
        }, {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -130 }
        }, {
            x: { align: "left", dx: 80 },
            y: { align: "top", dy: -150 }
        }] 
    },

    field: [{
        x: { align: "center" },
        y: { align: "center" }
    }, {
        x: { align: "center" },
        y: { align: "center" }
    }, {
        x: { align: "center", dx: 50 },
        y: { align: "center" }
    }],

    piecesPadding: [20, 20, 40],

    scoreViewClassic: {
        x: { align: "left", dx: 50 },
        y: { align: "center" },
        margin: 140
    },

    piecesView: {
        anchor: [{
            x: 0.5,
            y: 0.5
        }, {
            x: 1,
            y: 0.625
        }, {
            x: 0.5,
            y: 0.5
        }
        ]
    }
};
