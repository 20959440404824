/**
 * Created by Andrey Popov on 22.03.2023
 */

var HideAnimations = function (f) {
    cleverapps.audio.playSound(bundles.game.urls.lose_animation_effect);

    var game = this.game;
    var grid = game.grid;

    cleverapps.meta.compound(f, [
        function (f) {
            grid.clear(true);
            grid.fill(Grid.FILL_EFFECTS.LinearFromLeft, f);
        },

        function (f) {
            game.score.hide();
            game.pieces.onHide(true);

            this.goalsView.hide();

            grid.hideGrid(f);
        }.bind(this)
    ]);
};